import { createBrowserHistory } from "history";
import { Router as ReactRouter, Switch, Route } from "react-router-dom";
import { EquipmentPage } from "../pages/equipment";
import { HomePage } from "../pages/home";
import { TopPage } from "../pages/top";
import { AboutPage } from "../pages/about";
import { EventPage } from "../pages/event";
import { MembershipPage } from "../pages/membership";
import { ContactPage } from "../pages/contact";

const Router = () => {
  const history = createBrowserHistory();

  return (
    <ReactRouter history={history}>
      <Switch>
        <Route exact path={"/"} component={HomePage} />
        <Route exact path={"/top"} component={TopPage} />
        <Route exact path={"/about"} component={AboutPage} />
        <Route exact path={"/equipment"} component={EquipmentPage} />
        <Route exact path={"/event"} component={EventPage} />
        <Route exact path={"/membership"} component={MembershipPage} />
        <Route exact path={"/contact"} component={ContactPage} />
      </Switch>
    </ReactRouter>
  );
};

export { Router };
