import {
  IconButton,
  Button,
  ButtonGroup,
  Divider,
  styled,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const Wrapper = styled("div")(({ theme }) => ({
  position: "fixed",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: 60,
  backgroundColor: theme.palette.grey[900],
  borderBottom: "0.5px solid",
  borderColor: theme.palette.grey[700],
  [theme.breakpoints.down("md")]: {
    position: "fixed",
  },
}));

const PositionWrapper = styled("div")(({ theme }) => ({
  height: 60,
  display: "flex",
  alignItems: "center",
  flex: 1,
  justifyContent: "center",
}));

const HeaderLogo = styled("img")(({ theme }) => ({
  display: "block",
  height: 40,
  marginLeft: theme.spacing(12),
  marginRight: theme.spacing(10),
  [theme.breakpoints.down("lg")]: {
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(8),
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: 0,
    marginRight: 0,
  },
}));

const LogoButtonDivider = styled(Divider)(({ theme }) => ({
  height: 45,
  marginLeft: 0,
  borderRight: "0.5px solid",
  borderColor: theme.palette.grey[700],
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const HeadButtons = styled(ButtonGroup)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  variant: "text",
  ariaLabel: "text button group",
  fillWidth: true,
  flexGrow: 1,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const HeadButton = styled(Button)(({ theme }) => ({
  color: "white",
  height: 59,
  width: 150,
  border: "none",
  transition: theme.transitions.create(["background-color", "color"], {
    delay: 50,
  }),
  ":hover": {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.grey[50],
    border: "none",
  },
  [theme.breakpoints.down("lg")]: {
    width: 125,
  },
}));

const MenuDrawer = styled(Drawer)(({ theme }) => ({}));

const HamburgerMenuWrapper = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 0,
  zIndex: 1,
  height: 40,
  borderRadius: 2,
  backgroundColor: theme.palette.grey[900],
  color: theme.palette.grey[50],
  marginRight: theme.spacing(3),
  ":hover": { backgroundColor: theme.palette.grey[800], borderRadius: 2 },
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const HamburgerMenu = styled(MenuIcon)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

const MenuWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: theme.palette.grey[900],
  height: 300,
  borderBottom: "0.5px solid",
  borderColor: theme.palette.grey[700],
}));

const MenuLogoWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: 60,
}));

const MenuList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  padding: 0,
}));

const MenuListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  color: "white",
  width: "100%",
  height: 60,
  textAlign: "center",
  transition: theme.transitions.create(["color", "background-color"], {
    delay: 50,
  }),
  ":hover": {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.grey[50],
    cursor: "pointer",
  },
}));

const MenuListText = styled(ListItemText)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "bold",
  fontSize: 14,
}));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Wrapper,
  PositionWrapper,
  HeaderLogo,
  LogoButtonDivider,
  HeadButtons,
  HeadButton,
  MenuDrawer,
  HamburgerMenuWrapper,
  HamburgerMenu,
  MenuWrapper,
  MenuLogoWrapper,
  MenuList,
  MenuListItem,
  MenuListText,
};
