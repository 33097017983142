import React from "react";
import ReactDOM from "react-dom";
import { Router } from "./views/router";
import "./styles.css";
import { ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 500,
      sm: 800,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiButton: {
      variants: [],
    },
  },
  palette: {
    grey: {
      800: "rgb(35, 35, 35)",
      900: "rgb(20, 20, 20)",
      A100: "rgb(190, 0, 0)",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
