import React from "react";
import styles from "./styles";
import { useHistory } from "react-router";

export const Head = () => {
  const [state, setState] = React.useState({
    isOpen: false,
  });

  const history = useHistory();
  const {
    Wrapper,
    PositionWrapper,
    HeaderLogo,
    LogoButtonDivider,
    HeadButtons,
    HeadButton,
    MenuDrawer,
    HamburgerMenuWrapper,
    HamburgerMenu,
    MenuWrapper,
    MenuLogoWrapper,
    MenuList,
    MenuListItem,
    MenuListText,
  } = styles;

  const menuItems = [
    {
      title: "About",
      path: "./about",
    },
    {
      title: "Equipment",
      path: "./equipment",
    },
    {
      title: "Membership",
      path: "./membership",
    },
    {
      title: "Contact",
      path: "./contact",
    },
  ];

  return (
    <Wrapper>
      <PositionWrapper>
        <HeaderLogo
          src="images/bossLogo.png"
          onClick={() => history.push("/")}
        ></HeaderLogo>
        <LogoButtonDivider
          orientation="vertical"
          variant="middle"
        ></LogoButtonDivider>
        <HeadButtons>
          {menuItems.map((menuItem, index) => (
            <HeadButton key={index} onClick={() => history.push(menuItem.path)}>
              {menuItem.title}
            </HeadButton>
          ))}
        </HeadButtons>
        <HamburgerMenuWrapper
          onClick={() => {
            setState({ isOpen: true });
          }}
        >
          <HamburgerMenu fontSize="large"></HamburgerMenu>
        </HamburgerMenuWrapper>
        <MenuDrawer
          anchor={"top"}
          open={state.isOpen}
          onClose={() => {
            setState({ isOpen: false });
          }}
        >
          <MenuWrapper>
            <MenuLogoWrapper>
              <HeaderLogo
                src="images/bossLogo.png"
                onClick={() => history.push("/")}
              ></HeaderLogo>
            </MenuLogoWrapper>
            <MenuList>
              {menuItems.map((menuItem) => (
                <MenuListItem
                  key={menuItem.title}
                  onClick={() => history.push(menuItem.path)}
                >
                  <MenuListText>{menuItem.title}</MenuListText>
                </MenuListItem>
              ))}
            </MenuList>
          </MenuWrapper>
        </MenuDrawer>
      </PositionWrapper>
    </Wrapper>
  );
};
