import { Head } from "../../components/Organisms/head";
import { Foot } from "../../components/Organisms/foot";
import { Body } from "./body";
import styles from "./styles";

export const AboutPage = () => {
  const { Wrapper } = styles;

  return (
    <Wrapper>
      <Head></Head>
      <Body></Body>
      <Foot></Foot>
    </Wrapper>
  );
};
