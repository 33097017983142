import styles from "./styles";

export const Body = () => {
  const {
    Wrapper,
    PageTitle,
    Boss,
    ImageWrapper,
    ContentWrapper,
    ContentTextWrapper,
    ContentText,
    ItemWrapperRow,
    ContentTitle,
    AboutImage,
  } = styles;

  return (
    <Wrapper>
      <PageTitle>
        About <Boss>BOSS.</Boss>
      </PageTitle>
      <ItemWrapperRow>
        <ImageWrapper
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <AboutImage
            src="./images/bossFreeWeight1f.jpg"
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          ></AboutImage>
        </ImageWrapper>
        <ContentWrapper>
          <ContentTitle>Free Weights</ContentTitle>
          <ContentTextWrapper>
            <ContentText>
              フリーウェイトとはダンベルやバーベルのことで、最もベーシックなトレーニングの器具。軌道が固定されておらず、自分の力で安定した状態を保ちながらトレーニングを行うため動員する筋肉の部位が多く効率的に多くの筋肉を鍛えることができる。
            </ContentText>
          </ContentTextWrapper>
        </ContentWrapper>
      </ItemWrapperRow>
      <ItemWrapperRow>
        <ContentTextWrapper>
          <ContentText>
            マシンでのトレーニングは軌道が固定されているため怪我のリスクが少ないことに加えてウェイトを安定させることに集中する必要がないため、追い込みたい部位への意識がしやすくなり最後の追い込みなどに使えばトレーニング強度を高めることができる。
          </ContentText>
        </ContentTextWrapper>
        <ImageWrapper
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        >
          <AboutImage
            src="./images/bossChest1f.jpg"
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          ></AboutImage>
        </ImageWrapper>
      </ItemWrapperRow>
      <ItemWrapperRow>
        <ImageWrapper
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <AboutImage
            src="./images/bossHalfRack1f.jpg"
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
          ></AboutImage>
        </ImageWrapper>
        <ContentTextWrapper>
          <ContentText>
            BOSSでは定期的にイベントが開催されている。その内容は、一定の期間内でベンチプレスやスクワット、デッドリフトなどの重量の伸び率を競ったり単純に重量を競ったりするものだ。興味がある方、我こそはという方はぜひスタッフまでお声かっください。
          </ContentText>
        </ContentTextWrapper>
      </ItemWrapperRow>
    </Wrapper>
  );
};
