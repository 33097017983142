import { styled, Typography } from "@mui/material";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
}));

const OverviewWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexGrow: 1,
}));

const OverviewLabel = styled("h1")(({ theme }) => ({
  width: "100%",
  color: "white",
  fontSize: 25,
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(1),
}));

const OverviewContent = styled(Typography)(({ theme }) => ({
  color: "white",
  width: "80%",
  boxSizing: "border-box",
  padding: theme.spacing(2),
  margin: "0 auto",
  marginBottom: theme.spacing(18),
  borderTop: "0.5px solid #FF0000",
  borderBottom: "0.5px solid #FF0000",
}));

const BodyMiddleWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginBottom: theme.spacing(18),
}));

const InformationLabel = styled("h1")(({ theme }) => ({
  width: "100%",
  color: "white",
  fontSize: 25,
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(1),
}));

const ContentLabel = styled("h1")(({ theme }) => ({
  color: "white",
  fontSize: 25,
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(1),
}));

const NewsWrapper = styled("div")(({ theme }) => ({
  width: "50%",
}));

const NewsContent = styled("div")(({ theme }) => ({
  width: "80%",
  boxSizing: "border-box",
  padding: theme.spacing(4),
  margin: "0 auto",
  marginTop: theme.spacing(5),
  borderTop: "0.5px solid white",
  borderBottom: "0.5px solid white",
  color: "white",
}));

const SingleNews = styled(Typography)(({ theme }) => ({}));

const AreaWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  zIndex: 3,
  width: "50%",
}));

const AreaContent = styled("div")(({ theme }) => ({
  width: "80%",
  boxSizing: "border-box",
  margin: "0 auto",
}));

const AreaImage = styled(AwesomeSlider)(({ theme }) => ({
  color: "white",
  backgroundColor: "white",
}));

const InformationWrapper = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  color: "white",
  backgroundColor: theme.palette.grey[800],
  width: "85%",
  height: 500,
  margin: "0 auto",
  marginBottom: theme.spacing(8),
}));

const InformationContents = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
}));

const EmbeddedMap = styled("iframe")(({ theme }) => ({
  width: "50%",
  height: 300,
  border: 0,
  allowFullScreen: "",
  loading: "lazy",
  referrerPolicy: "no-referrer-when-downgrade",
}));

const Information = styled(Typography)(({ theme }) => ({
  whiteSpace: "pre",
  height: 300,
  width: "40%",
  fontWeight: "bold",
  fontSize: 20,
}));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Wrapper,
  OverviewLabel,
  OverviewWrapper,
  OverviewContent,
  ContentLabel,
  BodyMiddleWrapper,
  NewsWrapper,
  NewsContent,
  AreaWrapper,
  AreaContent,
  AreaImage,
  InformationWrapper,
  InformationContents,
  EmbeddedMap,
  Information,
  InformationLabel,
  SingleNews,
};
