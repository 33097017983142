import { Head } from "../../components/Organisms/head";
import { Foot } from "../../components/Organisms/foot";
import styles from "./styles";
import { ContactPageBody } from "./body";

export const ContactPage = () => {
  const { Wrapper } = styles;

  return (
    <Wrapper>
      <Head></Head>
      <ContactPageBody></ContactPageBody>
      <Foot></Foot>
    </Wrapper>
  );
};
