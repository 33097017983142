import styles from "./styles";
export const Body = () => {
  const {
    Wrapper,
    EventWrapper,
    EventItem,
    PageTitle,
    EventImage,
    EventTitle,
  } = styles;

  return (
    <Wrapper>
      <PageTitle>Event</PageTitle>
      <EventWrapper spacing={4}>
        <EventItem>
          <EventTitle variant="h5">デッドリフト更新会</EventTitle>
          <EventImage src="https://placehold.jp/150x150.png"></EventImage>
        </EventItem>
      </EventWrapper>
    </Wrapper>
  );
};
