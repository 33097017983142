import { styled, Typography } from "@mui/material";

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  marginTop: 90,
  [theme.breakpoints.down("md")]: {
    marginTop: 90,
  },
}));

const PageTitle = styled("label")(({ theme }) => ({
  fontSize: 30,
  fontWeight: "bold",
  marginBottom: theme.spacing(4),
}));

const Boss = styled("span")(({ theme }) => ({
  fontSize: 30,
  fontWeight: "bold",
  color: theme.palette.grey["A100"],
}));

const ItemWrapperRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "80%",
  backgroundColor: theme.palette.grey[900],
  marginBottom: theme.spacing(5),
  borderRadius: 4,
}));

const ImageWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "65%",
  borderRadius: 4,
}));

const ContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const ContentTextWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "80%",
  color: "white",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
}));

const ContentText = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  width: "90%",
}));

const ContentTitle = styled("label")(({ theme }) => ({
  fontSize: 25,
  fontWeight: "bold",
}));

const AboutImage = styled("img")(({ theme }) => ({
  display: "block",
  width: "100%",
  objectFit: "contain",
  borderRadius: 4,
}));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Wrapper,
  PageTitle,
  Boss,
  ImageWrapper,
  ContentWrapper,
  ContentTextWrapper,
  ContentText,
  ItemWrapperRow,
  ContentTitle,
  AboutImage,
};
