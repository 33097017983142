import styles from "./styles";
import { useHistory } from "react-router";

export const Foot = () => {
  const history = useHistory();

  const { Wrapper, FootLogo } = styles;

  return (
    <Wrapper>
      <FootLogo src="./images/bossLogo.png" onClick={() => history.push("/")} />
    </Wrapper>
  );
};
