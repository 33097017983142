import { styled } from "@mui/material";

const Wrapper = styled("div")(() => ({
  backgroundColor: "black",
}));

const BodyWrapper = styled("div")(({ theme }) => ({
  // height: 1340,
  width: "95%",
  backgroundColor: theme.palette.grey[900],
  margin: "0 auto",
  marginTop: theme.spacing(10),
  paddingTop: theme.spacing(8),
}));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Wrapper,
  BodyWrapper,
};
