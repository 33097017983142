import styles from "./styles";

interface SingleMembership {
  title: string;
  price: string;
  description: string;
  note: string;
  isLast: boolean;
}

export const Body = () => {
  const {
    Wrapper,
    PageTitle,
    MembershipWrapperLg,
    MembershipWrapperMd,
    SmMembershipWrapper,
    MembershipWrapperRow,
    SingleMembershipWrapper,
    SingleMembership,
    MembershipTitle,
    MembershipText,
  } = styles;

  const singleMemberships: SingleMembership[] = [
    {
      title: "フル会員",
      price: "¥11,000 / 月",
      description: "営業時間内いつでも利用可能",
      note: "※18歳以上",
      isLast: false,
    },
    {
      title: "デイ会員",
      price: "¥6,600 / 月",
      description: "9:00 ~ 18:00の間利用可能",
      note: "※18歳以上",
      isLast: false,
    },
    {
      title: "ナイト会員",
      price: "¥6,600 / 月",
      description: "18:00 ~ 23:00の間利用可能",
      note: "※18歳以上",
      isLast: false,
    },
    {
      title: "学生会員",
      price: "¥8,800 / 月",
      description: "営業時間内いつでも利用可能",
      note: "※18歳以上",
      isLast: false,
    },
    {
      title: "ファミリー会員",
      price: "¥6,600 / 月",
      description: "営業時間内いつでも利用可能",
      note: "※フル会員のご家族で18歳以上の方",
      isLast: false,
    },
    {
      title: "ビジター",
      price: "¥1,100",
      description: "営業時間内いつでも利用可能",
      note: "※18歳以上",
      isLast: false,
    },
    {
      title: "回数券",
      price: "¥15,000 / 10回",
      description: "営業時間内いつでも利用可能",
      note: "※18歳以上",
      isLast: false,
    },
    {
      title: "パーソナル",
      price: "¥3,300 / 60分",
      description: "ボスの都合のつく時\n(だいたい夜です。)",
      note: "※18歳以上",
      isLast: false,
    },
    {
      title: "パーソナル（11回）",
      price: "¥30,000",
      description: "ちょっと安くなるよ",
      note: "高校生以上",
      isLast: true,
    },
  ];

  const lgComposition: SingleMembership[][] = [];
  const mdComposition: SingleMembership[][] = [];
  const lgRowItems: number = 3;
  const mdRowItems: number = 2;
  let rowItems: SingleMembership[] = [];

  singleMemberships.forEach((singleMembership, index) => {
    if ((index + 1) % lgRowItems === 1) {
      rowItems = [singleMembership];
    } else if ((index + 1) % lgRowItems !== 1) {
      rowItems.push(singleMembership);
      if ((index + 1) % lgRowItems === 0 || singleMembership.isLast) {
        lgComposition.push(rowItems);
      }
    }
  });

  singleMemberships.forEach((singleMembership, index) => {
    if ((index + 1) % mdRowItems === 1) {
      rowItems = [singleMembership];
    } else if ((index + 1) % mdRowItems !== 1) {
      rowItems.push(singleMembership);
      if ((index + 1) % mdRowItems === 0 || singleMembership.isLast) {
        mdComposition.push(rowItems);
      }
    }
  });

  return (
    <Wrapper>
      <PageTitle>Membership</PageTitle>
      <MembershipWrapperLg>
        {lgComposition.map((lgRowComposition, index) => (
          <MembershipWrapperRow key={index}>
            {lgComposition[index].map((lgRowItem, index) => (
              <SingleMembershipWrapper key={index}>
                <SingleMembership>
                  <MembershipTitle variant="h6">
                    {lgRowItem.title}
                  </MembershipTitle>
                  <MembershipText>{lgRowItem.price}</MembershipText>
                  <MembershipText>{lgRowItem.description}</MembershipText>
                  <MembershipText>{lgRowItem.note}</MembershipText>
                </SingleMembership>
              </SingleMembershipWrapper>
            ))}
          </MembershipWrapperRow>
        ))}
      </MembershipWrapperLg>

      <MembershipWrapperMd>
        {mdComposition.map((mdRowComposition, index) => (
          <MembershipWrapperRow key={index}>
            {mdComposition[index].map((mdRowItem, index) => (
              <SingleMembershipWrapper key={index}>
                <SingleMembership>
                  <MembershipTitle variant="h6">
                    {mdRowItem.title}
                  </MembershipTitle>
                  <MembershipText>{mdRowItem.price}</MembershipText>
                  <MembershipText>{mdRowItem.description}</MembershipText>
                  <MembershipText>{mdRowItem.note}</MembershipText>
                </SingleMembership>
              </SingleMembershipWrapper>
            ))}
          </MembershipWrapperRow>
        ))}
      </MembershipWrapperMd>

      <SmMembershipWrapper>
        {singleMemberships.map((singleMembership, index) => (
          <SingleMembershipWrapper key={index}>
            <SingleMembership>
              <MembershipTitle variant="h6">
                {singleMembership.title}
              </MembershipTitle>
              <MembershipText>{singleMembership.price}</MembershipText>
              <MembershipText>{singleMembership.description}</MembershipText>
              <MembershipText>{singleMembership.note}</MembershipText>
            </SingleMembership>
          </SingleMembershipWrapper>
        ))}
      </SmMembershipWrapper>
    </Wrapper>
  );
};
