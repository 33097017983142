import { Head } from "../../components/Organisms/head";
import { Body } from "./body";
import styles from "./styles";

export const EquipmentPage = () => {
  const { Wrapper } = styles;
  return (
    <Wrapper>
      <Head></Head>
      <Body></Body>
    </Wrapper>
  );
};
