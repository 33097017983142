import { useCallback } from "react";
import styles from "./styles";
import axios from "axios";

export const ContactPageBody = () => {
  const {
    Wrapper,
    PageTitle,
    FormWrapper,
    Form,
    NameInput,
    MessageInput,
    SubmitButton,
  } = styles;

  const sendEmail = useCallback(async () => {
    const data = {
      name: "りき",
      subject: "test",
      email: "rikinotricky@gmail.com",
      desc: "test description",
    };
    const result = await axios.post(
      "https://esx5yucwv4.execute-api.ap-northeast-1.amazonaws.com/teststagename",
      JSON.stringify(data)
    );

    console.log(result);
  }, []);

  return (
    <Wrapper>
      <PageTitle>Contact</PageTitle>
      <FormWrapper>
        <Form>
          <NameInput
            label="Name"
            variant="filled"
            full-width="true"
            InputLabelProps={{ style: { color: "#C0C0C0" } }}
            InputProps={{ style: { color: "white" } }}
          />
          <MessageInput
            label="Message"
            variant="filled"
            full-width="true"
            InputLabelProps={{ style: { color: "#C0C0C0" } }}
            InputProps={{ style: { color: "white" } }}
            rows={5}
            multiline
          />
        </Form>
        <SubmitButton variant={"outlined"} onClick={sendEmail}>
          Submit
        </SubmitButton>
      </FormWrapper>
    </Wrapper>
  );
};
