import { styled, Stack, Paper, Typography } from "@mui/material";

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  marginTop: 90,
  [theme.breakpoints.down("md")]: {
    marginTop: 90,
  },
}));

const PageTitle = styled("label")(({ theme }) => ({
  fontSize: 30,
  fontWeight: "bold",
  color: "white",
  marginBottom: theme.spacing(4),
}));

const EventWrapper = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: theme.palette.grey[900],
  width: "90%",
  padding: theme.spacing(7),
}));

const EventItem = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.grey[800],
  width: "85%",
  color: theme.palette.grey[50],
  fontSize: 20,
  borderRadius: 3,
  textAlign: "center",
}));

const EventImage = styled("img")(({ theme }) => ({
  width: 150,
  height: 150,
}));

const EventTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
}));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Wrapper,
  EventWrapper,
  EventItem,
  PageTitle,
  EventImage,
  EventTitle,
};
