import { Card, styled, Typography } from "@mui/material";

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 90,
  backgroundColor: "black",
}));

const PageTitle = styled("label")(({ theme }) => ({
  fontSize: 30,
  fontWeight: "bold",
  color: "white",
  marginBottom: theme.spacing(4),
}));

const EquipmentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "90%",
}));

const EquipmentRowWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  width: "100%",
  marginBottom: theme.spacing(7),
}));

const SingleEquipmentWrapper = styled("div")(({ theme }) => ({
  flex: 1,
  color: "white",
}));

const SingleEquipment = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  backgroundColor: theme.palette.grey[900],
  borderRadius: 4,
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const EquipmentTitle = styled(Typography)(({ theme }) => ({
  color: "white",
  fontWeight: "bold",
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
}));

const EquipmentText = styled(Typography)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const EquipmentImage = styled("img")(({ theme }) => ({
  width: "100%",
}));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Wrapper,
  PageTitle,
  EquipmentWrapper,
  EquipmentRowWrapper,
  SingleEquipmentWrapper,
  SingleEquipment,
  EquipmentImage,
  EquipmentTitle,
  EquipmentText,
};
