import { styled } from "@mui/material";

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "black",
  width: "100%",
}));
// eslint-disable-next-line import/no-anonymous-default-export
export default { Wrapper };
