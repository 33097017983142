import { styled } from "@mui/material";

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "black",
  color: "white",
}));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Wrapper,
};
