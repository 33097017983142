import { useHistory } from "react-router";

export const TopPage = () => {
  const history = useHistory();
  return (
    <div>
      top page
      <button onClick={() => history.push("/")}>Home</button>
      <button onClick={() => history.push("/equipment")}>Equipment</button>
    </div>
  );
};
