import styles from "./styles";

export const HomePageBody = () => {
  const {
    Wrapper,
    OverviewLabel,
    OverviewWrapper,
    OverviewContent,
    ContentLabel,
    BodyMiddleWrapper,
    NewsWrapper,
    NewsContent,
    AreaWrapper,
    AreaContent,
    AreaImage,
    InformationWrapper,
    InformationContents,
    EmbeddedMap,
    Information,
    InformationLabel,
    SingleNews,
  } = styles;

  const images = [
    {
      source: "./images/bossAreaImage1.jpeg",
    },
    {
      source: "./images/bossAreaImage2.jpeg",
    },
    {
      source: "./images/bossAreaImage3.jpeg",
    },
  ];

  return (
    <Wrapper>
      <OverviewLabel>BOSS.</OverviewLabel>
      <OverviewWrapper>
        <OverviewContent>
          ウェイトトレーニングに特化したトレーニングジム「BOSS.」。
          充実したフリーウェイトや体の各部位を追い込めるマシンを取り揃えており、初心者から経験者の方まで満足いくワークアウトができます。
          また、入会を悩んでいる方や使用する頻度があまり多くない方向けにビジターでのご利用も可能ですのでお気軽にどうぞ。
        </OverviewContent>
      </OverviewWrapper>
      <BodyMiddleWrapper>
        <NewsWrapper>
          <ContentLabel>NEWS</ContentLabel>
          <NewsContent>
            <SingleNews>2022/03/28: あいうえお</SingleNews>
            <SingleNews>2022/03/28: あいうえお</SingleNews>
            <SingleNews>2022/03/28: あいうえお</SingleNews>
            <SingleNews>2022/03/28: あいうえお</SingleNews>
            <SingleNews>2022/03/28: あいうえお</SingleNews>
          </NewsContent>
        </NewsWrapper>
        <AreaWrapper>
          <ContentLabel>Area</ContentLabel>
          <AreaContent>
            <AreaImage
              media={images}
              bullets={true}
              organicArrows={false}
            ></AreaImage>
          </AreaContent>
        </AreaWrapper>
      </BodyMiddleWrapper>
      <InformationWrapper>
        <InformationLabel>INFORMATION</InformationLabel>
        <InformationContents>
          <EmbeddedMap src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3251.0522220986195!2d136.80215561519455!3d35.42873635138553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6003a92d9cf0340b%3A0x59e59f6317709479!2sWORKOUT%20STUDIO%20BOSS!5e0!3m2!1sja!2sjp!4v1648445359224!5m2!1sja!2sjp"></EmbeddedMap>
          <Information>{`営業時間\n\n平日・土曜: 9:00〜23:00\n\n日曜: 9:00〜20:00\n\n木曜定休`}</Information>
        </InformationContents>
      </InformationWrapper>
    </Wrapper>
  );
};
