import { Button, FormControl, styled, TextField } from "@mui/material";

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
  width: "100%",
  marginTop: 90,
}));

const PageTitle = styled("label")(({ theme }) => ({
  fontSize: 30,
  fontWeight: "bold",
  color: "white",
  marginBottom: theme.spacing(4),
}));

const FormWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  color: "white",
}));

const Form = styled(FormControl)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginLeft: theme.spacing(20),
  marginRight: theme.spacing(20),
  marginBottom: theme.spacing(2),
}));

const NameInput = styled(TextField)(({ theme }) => ({
  width: 300,
  backgroundColor: theme.palette.grey[900],
  borderRadius: 2,
  marginBottom: theme.spacing(1),
}));

const MessageInput = styled(TextField)(({ theme }) => ({
  width: 300,
  backgroundColor: theme.palette.grey[900],
  borderRadius: 2,
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: 2,
  color: "#C0C0C0",
  borderColor: "#C0C0C0",
}));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Wrapper,
  PageTitle,
  FormWrapper,
  Form,
  NameInput,
  MessageInput,
  SubmitButton,
};
