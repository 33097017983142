import { HomePageBody } from "./body";
import { Foot } from "../../components/Organisms/foot";
import { HomePageHead } from "./head";
import styles from "./styles";

export const HomePage = () => {
  const { Wrapper, BodyWrapper } = styles;

  return (
    <Wrapper>
      <HomePageHead></HomePageHead>
      <BodyWrapper>
        <HomePageBody></HomePageBody>
      </BodyWrapper>
      <Foot></Foot>
    </Wrapper>
  );
};
