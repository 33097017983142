import { Card, styled, Typography } from "@mui/material";

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 90,
  [theme.breakpoints.down("md")]: {
    marginTop: 90,
  },
}));

const PageTitle = styled("label")(({ theme }) => ({
  fontSize: 30,
  fontWeight: "bold",
  color: "white",
  marginBottom: theme.spacing(4),
}));

const MembershipWrapperLg = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "90%",
  backgroundColor: "black",
  padding: theme.spacing(4),
  paddingTop: 0,
  paddingBottom: 0,
  [theme.breakpoints.down("lg")]: { display: "none" },
}));

const MembershipWrapperMd = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "90%",
  backgroundColor: "black",
  padding: theme.spacing(4),
  paddingTop: 0,
  paddingBottom: 0,
  [theme.breakpoints.up("lg")]: { display: "none" },
  [theme.breakpoints.down("sm")]: { display: "none" },
}));

const SmMembershipWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "70%",
  [theme.breakpoints.down("xs")]: { width: "90%" },
  [theme.breakpoints.up("sm")]: { display: "none" },
}));

const MembershipWrapperRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  paddingBottom: theme.spacing(7),
}));

const SingleMembershipWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    paddingBottom: theme.spacing(7),
    flexGrow: 1,
  },
}));

const SingleMembership = styled(Card)(({ theme }) => {
  const hoveredBackgroundColor = theme.palette.grey[700];
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: 250,
    color: theme.palette.grey[50],
    width: "85%",
    backgroundColor: theme.palette.grey[900],
    borderRadius: 4,
    padding: theme.spacing(1),
    transition: theme.transitions.create("background-color", { delay: 50 }),
    ":hover": {
      backgroundColor: hoveredBackgroundColor,
      color: theme.palette.getContrastText(hoveredBackgroundColor),
    },
  };
});

const MembershipTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontWeight: "bold",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(5),
}));

const MembershipText = styled(Typography)(({ theme }) => ({
  whiteSpace: "pre-line",
  marginBottom: theme.spacing(3),
}));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Wrapper,
  PageTitle,
  MembershipWrapperLg,
  MembershipWrapperMd,
  SmMembershipWrapper,
  MembershipWrapperRow,
  SingleMembershipWrapper,
  SingleMembership,
  MembershipTitle,
  MembershipText,
};
