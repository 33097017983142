import { List, ListItemText, styled, ListItemButton } from "@mui/material";
import { Typography } from "@mui/material";

const Wrapper = styled("div")(({ theme }) => ({
  height: 600,
  display: "flex",
  flexDirection: "row",
  position: "relative",
  backgroundImage: `url(./images/bossTopImage.png)`,
  backgroundSize: "cover",
  backgroundPositionY: "top",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const LgWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: 4,
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
}));

const SideMenuWrapper = styled("div")(({ theme }) => ({
  width: "20%",
  backgroundColor: "rgba(0,0,0,0.8)",
  color: "white",
  borderRight: "0.5px solid",
  borderColor: theme.palette.grey[700],
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

const MenuButton = styled(ListItemButton)(({ theme }) => {
  const backgroundColor = theme.palette.grey[50];
  return {
    height: "12%",
    textAlign: "center",
    transition: theme.transitions.create(["background-color", "color"], {
      delay: 50,
    }),
    "&:hover": {
      backgroundColor: backgroundColor,
      color: theme.palette.getContrastText(backgroundColor),
    },
  };
});

const MenuButtonText = styled(ListItemText)(() => ({}));

const TitleWrapper = styled(List)(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: theme.spacing(3),
  justifyContent: "start",
  [theme.breakpoints.down("lg")]: {
    paddingLeft: 0,
    justifyContent: "center",
    textAlign: "center",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  whiteSpace: "pre",
  color: theme.palette.common.white,
  fontSize: 80,
  fontWeight: "bold",
}));

const BackgroundLayer = styled("div")(({ theme }) => ({}));

const TopLogo = styled("img")(({ theme }) => ({
  display: "block",
  height: 50,
  margin: theme.spacing(6, "auto"),
}));

const Boss = styled("span")(({ theme }) => ({
  color: theme.palette.grey["A100"],
}));
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Wrapper,
  LgWrapper,
  SideMenuWrapper,
  TitleWrapper,
  Title,
  BackgroundLayer,
  MenuButton,
  MenuButtonText,
  TopLogo,
  Boss,
};
