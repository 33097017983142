import styles from "./styles";

export const Body = () => {
  const {
    Wrapper,
    PageTitle,
    EquipmentWrapper,
    EquipmentRowWrapper,
    SingleEquipmentWrapper,
    SingleEquipment,
    EquipmentImage,
    EquipmentTitle,
    EquipmentText,
  } = styles;

  const equipments = [
    {
      title: "Dumbbell",
      path: "./images/bossDumbbell1f.jpg",
      description:
        "Placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder",
    },
    {
      title: "Benches",
      path: "./images/bossBenches1f.jpg",
      description:
        "Placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder",
    },
    {
      title: "Half Rack",
      path: "./images/bossHalfRack1f.jpg",
      description:
        "Placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder",
    },
    {
      title: "Bench Press Rack",
      path: "./images/bossBenchPress1f.jpg",
      description:
        "Placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder",
    },
    {
      title: "Chest Press",
      path: "./images/bossChest1f.jpg",
      description:
        "Placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder",
    },
    {
      title: "Lat Pull Down",
      path: "./images/bossLat2f.jpg",
      description:
        "Placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder",
    },
    {
      title: "Leg Press & Hack Squat",
      path: "./images/bossLegPress1f.jpg",
      description:
        "Placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder",
    },
    {
      title: "Leg Extension",
      path: "./images/./images/bossLegExtenshion1f.jpg",
      description:
        "Placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder",
    },
    {
      title: "Rowing Machine",
      path: "./images/bossRow1f.jpg",
      description:
        "Placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder",
    },
    {
      title: "Dumbbell",
      path: "./images/bossDumbbell2f.jpg",
      description:
        "Placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder",
    },
    {
      title: "Half Rack",
      path: "./images/bossHalfRack2f.jpg",
      description:
        "Placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder",
    },
    {
      title: "Crunch Machine",
      path: "./images/bossCrunch2f.jpg",
      description:
        "Placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder",
    },
    {
      title: "Cable Machine",
      path: "./images/bossCable2f.jpg",
      description:
        "Placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder",
    },
    {
      title: "Arm Curl Stand",
      path: "./images/bossArmCurl2f.jpg",
      description:
        "Placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder",
    },
    {
      title: "Treadmill",
      path: "./images/bossTredmil.jpg",
      description:
        "Placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder placeholder",
    },
  ];

  const lgEquipments = [];

  equipments.forEach((equipment, index) => {
    if (index % 2 === 1) {
    }
  });

  return (
    <Wrapper>
      <PageTitle>Equipment</PageTitle>
      <EquipmentWrapper>
        <EquipmentRowWrapper>
          {}
          <SingleEquipmentWrapper>
            <SingleEquipment>
              <EquipmentImage src="./images/bossDumbbell1f.jpg"></EquipmentImage>
              <EquipmentTitle variant="h5">Dumbbell</EquipmentTitle>
              <EquipmentText>
                Placeholder placeholder placeholder placeholder placeholder
                placeholder placeholder placeholder placeholder
              </EquipmentText>
            </SingleEquipment>
          </SingleEquipmentWrapper>
          <SingleEquipmentWrapper>
            <SingleEquipment>
              <EquipmentImage src="./images/bossBenches1f.jpg"></EquipmentImage>
              <EquipmentTitle variant="h5">Benches</EquipmentTitle>
              <EquipmentText>
                Placeholder placeholder placeholder placeholder placeholder
                placeholder placeholder placeholder placeholder
              </EquipmentText>
            </SingleEquipment>
          </SingleEquipmentWrapper>
        </EquipmentRowWrapper>
        <EquipmentRowWrapper>
          <SingleEquipmentWrapper>
            <SingleEquipment>
              <EquipmentImage src="./images/bossHalfRack1f.jpg"></EquipmentImage>
              <EquipmentTitle variant="h5">Half Rack</EquipmentTitle>
              <EquipmentText>
                Placeholder placeholder placeholder placeholder placeholder
                placeholder placeholder placeholder placeholder
              </EquipmentText>
            </SingleEquipment>
          </SingleEquipmentWrapper>
          <SingleEquipmentWrapper>
            <SingleEquipment>
              <EquipmentImage src="./images/bossBenchPress1f.jpg"></EquipmentImage>
              <EquipmentTitle variant="h5">Bench Press Rack</EquipmentTitle>
              <EquipmentText>
                Placeholder placeholder placeholder placeholder placeholder
                placeholder placeholder placeholder placeholder
              </EquipmentText>
            </SingleEquipment>
          </SingleEquipmentWrapper>
        </EquipmentRowWrapper>
        <EquipmentRowWrapper>
          <SingleEquipmentWrapper>
            <SingleEquipment>
              <EquipmentImage src="./images/bossChest1f.jpg"></EquipmentImage>
              <EquipmentTitle variant="h5">Chest Press</EquipmentTitle>
              <EquipmentText>
                Placeholder placeholder placeholder placeholder placeholder
                placeholder placeholder placeholder placeholder
              </EquipmentText>
            </SingleEquipment>
          </SingleEquipmentWrapper>
          <SingleEquipmentWrapper>
            <SingleEquipment>
              <EquipmentImage src="./images/bossLat2f.jpg"></EquipmentImage>
              <EquipmentTitle variant="h5">Lat Pull Down</EquipmentTitle>
              <EquipmentText>
                Placeholder placeholder placeholder placeholder placeholder
                placeholder placeholder placeholder placeholder
              </EquipmentText>
            </SingleEquipment>
          </SingleEquipmentWrapper>
        </EquipmentRowWrapper>
        <EquipmentRowWrapper>
          <SingleEquipmentWrapper>
            <SingleEquipment>
              <EquipmentImage src="./images/bossLegPress1f.jpg"></EquipmentImage>
              <EquipmentTitle variant="h5">
                Leg Press & Hack Squat
              </EquipmentTitle>
              <EquipmentText>
                Placeholder placeholder placeholder placeholder placeholder
                placeholder placeholder placeholder placeholder
              </EquipmentText>
            </SingleEquipment>
          </SingleEquipmentWrapper>
          <SingleEquipmentWrapper>
            <SingleEquipment>
              <EquipmentImage src="./images/bossLegExtenshion1f.jpg"></EquipmentImage>
              <EquipmentTitle variant="h5">Leg Extension</EquipmentTitle>
              <EquipmentText>
                Placeholder placeholder placeholder placeholder placeholder
                placeholder placeholder placeholder placeholder
              </EquipmentText>
            </SingleEquipment>
          </SingleEquipmentWrapper>
        </EquipmentRowWrapper>
        <EquipmentRowWrapper>
          <SingleEquipmentWrapper>
            <SingleEquipment>
              <EquipmentImage src="./images/bossRow1f.jpg"></EquipmentImage>
              <EquipmentTitle variant="h5">Row Machine</EquipmentTitle>
              <EquipmentText>
                Placeholder placeholder placeholder placeholder placeholder
                placeholder placeholder placeholder placeholder
              </EquipmentText>
            </SingleEquipment>
          </SingleEquipmentWrapper>
          <SingleEquipmentWrapper>
            <SingleEquipment>
              <EquipmentImage src="./images/bossDumbbell2f.jpg"></EquipmentImage>
              <EquipmentTitle variant="h5">Dumbbell</EquipmentTitle>
              <EquipmentText>
                Placeholder placeholder placeholder placeholder placeholder
                placeholder placeholder placeholder placeholder
              </EquipmentText>
            </SingleEquipment>
          </SingleEquipmentWrapper>
        </EquipmentRowWrapper>
        <EquipmentRowWrapper>
          <SingleEquipmentWrapper>
            <SingleEquipment>
              <EquipmentImage src="./images/bossHalfRack2f.jpg"></EquipmentImage>
              <EquipmentTitle variant="h5">Half Rack</EquipmentTitle>
              <EquipmentText>
                Placeholder placeholder placeholder placeholder placeholder
                placeholder placeholder placeholder placeholder
              </EquipmentText>
            </SingleEquipment>
          </SingleEquipmentWrapper>
          <SingleEquipmentWrapper>
            <SingleEquipment>
              <EquipmentImage src="./images/bossSideRaise2f.jpg"></EquipmentImage>
              <EquipmentTitle variant="h5">Raise Machine</EquipmentTitle>
              <EquipmentText>
                Placeholder placeholder placeholder placeholder placeholder
                placeholder placeholder placeholder placeholder
              </EquipmentText>
            </SingleEquipment>
          </SingleEquipmentWrapper>
        </EquipmentRowWrapper>
        <EquipmentRowWrapper>
          <SingleEquipmentWrapper>
            <SingleEquipment>
              <EquipmentImage src="./images/bossCrunch2f.jpg"></EquipmentImage>
              <EquipmentTitle variant="h5">Crunch Machine</EquipmentTitle>
              <EquipmentText>
                Placeholder placeholder placeholder placeholder placeholder
                placeholder placeholder placeholder placeholder
              </EquipmentText>
            </SingleEquipment>
          </SingleEquipmentWrapper>
          <SingleEquipmentWrapper>
            <SingleEquipment>
              <EquipmentImage src="./images/bossCable2f.jpg"></EquipmentImage>
              <EquipmentTitle variant="h5">Cable Machine</EquipmentTitle>
              <EquipmentText>
                Placeholder placeholder placeholder placeholder placeholder
                placeholder placeholder placeholder placeholder
              </EquipmentText>
            </SingleEquipment>
          </SingleEquipmentWrapper>
        </EquipmentRowWrapper>
        <EquipmentRowWrapper>
          <SingleEquipmentWrapper>
            <SingleEquipment>
              <EquipmentImage src="./images/bossArmCurl2f.jpg"></EquipmentImage>
              <EquipmentTitle variant="h5">Arm Curl Stand</EquipmentTitle>
              <EquipmentText>
                Placeholder placeholder placeholder placeholder placeholder
                placeholder placeholder placeholder placeholder
              </EquipmentText>
            </SingleEquipment>
          </SingleEquipmentWrapper>
          <SingleEquipmentWrapper>
            <SingleEquipment>
              <EquipmentImage src="./images/bossFreeWeight1f.jpg"></EquipmentImage>
              <EquipmentTitle variant="h5">Bench</EquipmentTitle>
              <EquipmentText>
                Placeholder placeholder placeholder placeholder placeholder
                placeholder placeholder placeholder placeholder
              </EquipmentText>
            </SingleEquipment>
          </SingleEquipmentWrapper>
        </EquipmentRowWrapper>
      </EquipmentWrapper>
    </Wrapper>
  );
};
