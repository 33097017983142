import { styled } from "@mui/material";

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  width: "100%",
  borderTop: "0.5px solid",
  borderColor: theme.palette.grey[700],
  padding: theme.spacing(4),
  marginTop: theme.spacing(7),
}));

const FootLogo = styled("img")(({ theme }) => ({
  display: "block",
  height: 50,
}));

// eslint-disable-next-line import/no-anonymous-default-export
export default { Wrapper, FootLogo };
