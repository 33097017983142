import styles from "./styles";
import { useHistory } from "react-router";
import { Head } from "../../../components/Organisms/head";

export const HomePageHead = () => {
  const {
    Wrapper,
    LgWrapper,
    SideMenuWrapper,
    TitleWrapper,
    Title,
    BackgroundLayer,
    MenuButton,
    MenuButtonText,
    TopLogo,
    Boss,
  } = styles;

  const history = useHistory();

  const menuItems = [
    {
      title: "About",
      path: "./about",
    },
    {
      title: "Equipment",
      path: "./equipment",
    },
    {
      title: "Membership",
      path: "./membership",
    },
    {
      title: "Contact",
      path: "./contact",
    },
  ];

  return (
    <Wrapper>
      <BackgroundLayer></BackgroundLayer>
      <SideMenuWrapper>
        <TopLogo src="images/bossLogo.png" onClick={() => history.push("./")} />
        {menuItems.map((menuItem, index) => (
          <MenuButton key={index}>
            <MenuButtonText
              primary={menuItem.title}
              onClick={() => history.push(menuItem.path)}
            />
          </MenuButton>
        ))}
      </SideMenuWrapper>
      <TitleWrapper>
        <Title>
          {`WORKOUT STUDIO\n`}
          <Boss>BOSS.</Boss>
        </Title>
      </TitleWrapper>
      <LgWrapper>
        <Head></Head>
      </LgWrapper>
    </Wrapper>
  );
};
